import { useNavigate } from "react-router";

function App() {
  let navigate = useNavigate();

  return (
    <div id="login-container">
      <div id="initial-screen">
        <h1>Ilim College</h1>
        <button
          id="parent-signin-button"
          type="button"
          onClick={() => {
            navigate("/parents");
          }}
        >
          Parent Sign In
        </button>
        <button
          id="staff-student-signin-button"
          type="button"
          onClick={() => {
            window.location.href = "https://accounts.google.com";
          }}
        >
          Staff and Students
        </button>
      </div>
    </div>
  );
}

export default App;
