import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import Parents from "./parents";
import EmailSignIn from "./parents/emailSignIn";
import EmailSignInWithLink from "./parents/emailSignInWithLink";
import FinishEmailSignInWithLink from "./parents/finishEmailSignInWithLink";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
    </Routes>
    <Routes>
      <Route path="/parents" element={<Parents />} />
    </Routes>
    <Routes>
      <Route path="/parents/email" element={<EmailSignIn />} />
    </Routes>
    <Routes>
      <Route path="/parents/email/link" element={<EmailSignInWithLink />} />
    </Routes>
    <Routes>
      <Route
        path="/parents/email/link/finish"
        element={<FinishEmailSignInWithLink />}
      />
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
