import { useNavigate } from "react-router";
import { auth } from "../firebaseConfig";
import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithPopup,
  OAuthProvider,
} from "firebase/auth";

function Index() {
  let navigate = useNavigate();

  const provider = new GoogleAuthProvider();
  const facebookProvider = new FacebookAuthProvider();
  const microsoftProvider = new OAuthProvider("microsoft.com");
  const appleProvider = new OAuthProvider("apple.com");

  const signInWithGoogle = async () => {
    try {
      await signInWithPopup(auth, provider);

      // Print token to test the middlewares later on via HTTP client
      // console.log(await auth.currentUser.getIdToken(true));

      const { claims } = await auth.currentUser.getIdTokenResult(true);

      const message = `Signed in as: ${claims.email}, UID: ${claims.user_id}`;
      console.log(message);
      alert(message);
    } catch (error) {
      console.log(error);
    }
  };

  const signInWithMicrosoft = () => {
    signInWithPopup(auth, microsoftProvider)
      .then((result) => {
        // Access the signed-in user info.
        const user = result.user;
        console.log("User Info:", user);
        const message = `Signed in as: ${user.displayName}, UID: ${user.uid}`;
        alert(message);
      })
      .catch((error) => {
        console.error("Error during sign-in:", error);
      });
  };
  const signInWithApple = () => {
    signInWithPopup(auth, appleProvider)
      .then((result) => {
        // Access the signed-in user info.
        const user = result.user;
        console.log("User Info:", user);
        const message = `Signed in as: ${user.displayName}, UID: ${user.uid}`;
        alert(message);
      })
      .catch((error) => {
        console.error("Error during sign-in:", error);
      });
  };

  const signInWithFacebook = () => {
    signInWithPopup(auth, facebookProvider)
      .then((result) => {
        const user = result.user;
        console.log("User Info:", user);
        const message = `Signed in as: ${user.displayName}, UID: ${user.uid}`;
        alert(message);
      })
      .catch((error) => {
        console.error("Error during login:", error);
      });
  };
  return (
    <div id="login-container">
      <div id="parent-signin-screen">
        <button
          type="button"
          id="apple-signin"
          className="sso-button"
          onClick={signInWithApple}
        >
          Sign in with Apple
        </button>
        <button
          type="button"
          id="google-signin"
          className="sso-button"
          onClick={signInWithGoogle}
        >
          Sign in with Google
        </button>
        <button
          type="button"
          id="microsoft-signin"
          className="sso-button"
          onClick={signInWithMicrosoft}
        >
          Sign in with Microsoft
        </button>
        <button
          type="button"
          id="facebook-signin"
          className="sso-button"
          onClick={signInWithFacebook}
        >
          Sign in with Facebook
        </button>
        <button
          id="signin-with-email"
          type="button"
          onClick={() => {
            navigate("/parents/email");
          }}
        >
          Sign in with Email
        </button>
      </div>
    </div>
  );
}

export default Index;
