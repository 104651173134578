import { useState } from "react";
import { useNavigate } from "react-router";
import { auth } from "../firebaseConfig";
import {
  signInWithEmailAndPassword,
  sendEmailVerification,
  signOut,
} from "firebase/auth";

function EmailSignIn() {
  let navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");

  const signInWithEmail = async (email, password) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      // console.log(await auth.currentUser.getIdToken(true));

      if (auth.currentUser) {
        const { claims } = await auth.currentUser.getIdTokenResult(true);

        if (!claims.email_verified) {
          await sendEmailVerification(auth.currentUser, {
            url: window.location.origin,
          });
          setMessage(
            "Please verify your email before signing in. We have sent you another verification email"
          );
          await signOut(auth);
          return;
        }

        console.log(claims);
        const message = `Signed in as: ${claims.email}, UID: ${claims.user_id}`;
        console.log(message);
        alert(message);
      }
    } catch (err) {
      console.log("Unexpected error: ", err);
    }
  };

  return (
    <div id="login-container">
      <div id="email-password-section">
        <h2>Sign in with your email</h2>
        <p>{message}</p>
        <input
          type="email"
          placeholder="Email Address"
          id="email-input"
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          id="password-input"
          onChange={(e) => setPassword(e.target.value)}
        />
        <a id="forgot-password-link">I forgot my password.</a>
        <button
          id="signin-button"
          onClick={() => signInWithEmail(email, password)}
        >
          Sign in
        </button>
        <a
          id="email-magic-link"
          type="button"
          onClick={() => {
            navigate("/parents/email/link");
          }}
        >
          Sign in with Email Magic Link
        </a>
      </div>
    </div>
  );
}

export default EmailSignIn;
