import { useState } from "react";
import { auth } from "../firebaseConfig";
import { sendSignInLinkToEmail } from "firebase/auth";

function EmailSignInWithLink() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const sendEmailLink = async () => {
    const actionCodeSettings = {
      url: `${window.location.origin}/parents/email/link/finish?email=${email}`,
      handleCodeInApp: true,
    };

    try {
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      window.localStorage.setItem("emailForSignIn", email);
      setMessage("Email link sent! Check your inbox.");
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };

  if (message) {
    return (
      <div id="login-container">
        <div id="email-magic-link-section">
          <p>{message}</p>
        </div>
      </div>
    );
  }
  return (
    <div id="login-container">
      <div id="email-magic-link-section">
        <h2>Enter Your Email</h2>
        <p>
          Please enter your Ilim College linked email address. Click Send
          Passcode and check your email. Enter the passcode sent to your email
          and verify to continue.
        </p>
        <input
          type="email"
          placeholder="Email Address"
          id="magic-email-input"
          onChange={(e) => setEmail(e.target.value)}
        />
        <button id="send-passcode-button">Send Passcode</button>
        <button id="continue-button" type="button" onClick={sendEmailLink}>
          Continue
        </button>
      </div>
    </div>
  );
}

export default EmailSignInWithLink;
