import { isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import { auth } from "../firebaseConfig";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router";

function FinishEmailSignInWithLink() {
  const [searchParams] = useSearchParams();

  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    const emailFromUrl = searchParams.get("email");
    if (emailFromUrl) {
      setEmail(emailFromUrl);
    }
  }, [searchParams]);

  const completeSignIn = async () => {
    try {
      const emailForSignIn =
        email || window.localStorage.getItem("emailForSignIn");
      if (!emailForSignIn) {
        throw new Error("Email is required to complete sign-in.");
      }
      if (isSignInWithEmailLink(auth, window.location.href)) {
        const result = await signInWithEmailLink(
          auth,
          emailForSignIn,
          window.location.href
        );
        setMessage("Successfully signed in!");
        window.localStorage.removeItem("emailForSignIn");
        console.log("User Info:", result.user); // Use result.user for further actions

        const message = `Signed in as: ${result.user.email}, UID: ${result.user.uid}`;
        console.log(message);
        alert(message);
      } else {
        setMessage("Invalid link!");
      }
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };

  return (
    <div id="login-container">
      <div id="email-magic-link-section">
        <p>{message}</p>
        {!email && (
          <>
            <h2>Enter Your Email</h2>
            <input
              type="email"
              placeholder="Email Address"
              id="magic-email-input"
            />
          </>
        )}

        <button id="continue-button" type="button" onClick={completeSignIn}>
          Complete Sign-In
        </button>
      </div>
    </div>
  );
}

export default FinishEmailSignInWithLink;
