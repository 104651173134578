import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

var firebaseConfig = {
  apiKey: "AIzaSyBNvieCkOMLwVGgv1rXPOxfbZxUGBdDRkY",
  authDomain: "auth.ilimcollege.vic.edu.au",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
